import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'

// @ts-ignore
import {
  Bolder,
  Header1,
  Header2,
  Header3,
  Paragraph,
} from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import Banner1 from '@/components/Banner1/Banner1'
import DownloadFormBanner from '@/components/DownloadFormBanner/DownloadFormBanner'
import Image1 from '@/assets/images/konsultacje-behawioralne-1.png'
import Image2 from '@/assets/images/konsultacje-behawioralne-2.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;

  img {
    width: 600px;
    max-width: 90%;
    margin: 30px auto;
  }
`

const Description = styled.div`
  padding: 50px 0;

  img {
    margin: 50px 0;
  }

  @media (max-width: 991px) {
    padding: 0;

    img {
      margin: 15px 0;
    }
  }
`

const Pricing = styled.div`
  display: grid;
  grid-row-gap: 30px;
  justify-content: center;
  align-content: center;
  padding: 50px 0 70px;

  @media (max-width: 991px) {
    grid-row-gap: 15px;
    padding: 30px 0;
  }
`

const Head = () => (
  <>
    <title>Pet School - Konsultacje behawioralne psów</title>
  </>
)

const DogBehavioralConsultationsPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <img
              alt="Pet School - konsultacje behawioralne psów"
              src={Image1}
            />

            <Description>
              <Header2 marginBottom={39}>
                <Bolder>Konsultacje behawioralne psów</Bolder>
              </Header2>

              <Paragraph>
                Na konsultacji indywidualnej, która odbywa się u klienta,
                analizuję dokładnie problem, zbieram dokładny wywiad oraz
                przyglądam się komunikacji i relacji pomiędzy psem a
                przewodnikiem. Staram się dobierać metody pracy, które będą w
                danym przypadku najskuteczniejsze.
              </Paragraph>

              <Paragraph>
                Zajmuje się między innymi takimi problemami jak: nadmierna
                lękliwość i fobie, zaburzenia separacyjne, nadpobudliwość,
                agresja do ludzi i zwierząt. Również pomagam wprowadzić psa do
                kota oraz drugiego psa do domu.
              </Paragraph>

              <img
                alt="Pet School - konsultacje behawioralne psów"
                src={Image2}
              />

              <Paragraph>
                Oferuję doradztwo w wyborze rasy, hodowli oraz adopcji oraz
                pierwszych dni w nowym domu zarówno szczeniaka jak i psów
                dorosłych.
              </Paragraph>

              <Paragraph>
                Podczas konsultacji dotyczących wychowania szczeniaczka
                opowiadam między innymi o etapach rozwoju psa, specyfice rasy,
                potrzebach psa i jak prawidłowo je zaspokajać, jak przeprowadzić
                prawidłową socjalizację i habituację, jak nauczyć psa
                odpoczynku, wprowadzenie nauki zostawania samemu w domu,
                treningu czystości, o komunikacji psów oraz najważniejsze
                akcesoria na spacery i do domu.
              </Paragraph>
            </Description>
          </Container>

          <Banner1>
            <Header3>
              <Bolder>
                Zawsze po konsultacji, wysyłam klientowi zalecenia wraz z planem
                pracy i materiałami z zakresu komunikacji psów.
              </Bolder>
            </Header3>
          </Banner1>

          <Container>
            <Pricing>
              <div>
                <Paragraph>
                  Koszt i czas trwania pierwszej konsultacji:
                </Paragraph>
                <Header1>
                  <Bolder>200 zł / 90 minut</Bolder>
                </Header1>
              </div>

              <div>
                <Paragraph>
                  Koszt i czas trwania każdej kolejnej konsultacji:
                </Paragraph>
                <Header1>
                  <Bolder>120 zł / 60 minut</Bolder>
                </Header1>
              </div>

              <div>
                <Paragraph>
                  *Za każde rozpoczęte <strong>30 minut</strong> doliczane jest
                  dodatkowo <strong>30 zł</strong>.
                </Paragraph>
              </div>

              <Paragraph>
                Częstotliwość:{` `}
                <Bolder>
                  Co dwa tygodnie lub do ustalenia indywidualnie z klientem
                </Bolder>
              </Paragraph>
            </Pricing>
          </Container>

          <DownloadFormBanner dog={true} />
        </Wrapper>
      </Layout>
    </>
  )
}

export default DogBehavioralConsultationsPage
